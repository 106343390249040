/* eslint-disable @next/next/no-page-custom-font */
// import '../styles/globals.css'
import type { AppProps } from "next/app";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GlobalStylesWrapper from "styles/global";
import "react-alice-carousel/lib/alice-carousel.css";
import SiteContextoProvider from "context/context";
import axios from "axios";
import Head from "next/head";
import { DefaultSeo } from "next-seo";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { getCsrfToken } from "funcoes/getToken";
import { parse, serialize } from "cookie";
import { randomBytes } from "crypto";
import { NextPageContext } from "next";
import { dataCookie } from "funcoes/dataCookie";
import { utmCookie } from "funcoes/utmCookie";

//teste

const App = ({
  Component,
  pageProps,
  menuData,
  restricoes,
  configuracoes,
}: AppProps & { menuData: any; restricoes: any, configuracoes: any }) => {
  const router = useRouter();
  
  useEffect(() => {
    if (typeof document !== undefined && router.query !== undefined){
      if(router.query.utm_campaign !== undefined){
        utmCookie(document, router.query);
      }
      
    } 
    
    if (typeof window !== "undefined" && window.gtag) {
      // Função para enviar o evento de pageview

      const handleRouteChange = (url: string) => {
        window.gtag("config", `${process.env.NEXT_PUBLIC_GTM_TAG}`, {
          page_path: url,
        });
      };

      // Monitorar a mudança de rota
      router.events.on("routeChangeComplete", handleRouteChange);

      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [router.events, router.query]);

  
  
  return (
    <SiteContextoProvider menu={menuData} restricoes={restricoes} configSite={configuracoes}>
      <GlobalStylesWrapper
        config={{
          main: "main",
          noauth: "noauth",
          minimal: "minimal",
          suites: {
            caracteristicas: {
              icon: {
                size: {
                  default: "8px",
                  xs: "8px",
                  sm: "8px",
                  md: "8px",
                  lg: "8px",
                  xl: "8px",
                },
                color: "#d09e2c",
              },
              fontSize: {
                default: "14px",
                xs: "14px",
                sm: "14px",
                md: "14px",
                lg: "14px",
                xl: "14px",
              },
              lineHeight: {
                default: "2",
                xs: "2",
                sm: "2",
                md: "2",
                lg: "2",
                xl: "2",
              },
            },
          },
          margin: {
            m1: "15px",
            m2: "20px",
            m3: "30px",
            m4: "40px",
          },
          padding: {
            m1: "15px",
            m2: "20px",
            m3: "30px",
            m4: "40px",
          },
          colors: {
            cinza: "#848484",
            dourado: "#d09e2c",
            roxo: "#771d50",
            laranja: "#ff8f00",
            tiffany: "#3ACBC7",
            tiffanyClaro: "#d8f4f3",
            fundoInput: "#f1f1f1",
          },
          containers: {
            principal: {
              margin: {
                default: "0px 0px 60px 0px",
                xs: "0px 0px 60px 0px",
                sm: "0px 0px 60px 0px",
                md: "0px 0px 60px 0px",
                lg: "0px 0px 60px 0px",
                xl: "0px 0px 60px 0px",
              },
              padding: {
                default: "0px 0px 0px 0px",
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
            },
            blocoSite: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: {
                default: "40px 0px 0px 0px",
                xs: "40px 0px 0px 0px",
                sm: "40px 0px 0px 0px",
                md: "40px 0px 0px 0px",
                lg: "40px 0px 0px 0px",
                xl: "80px 0px 0px 0px",
              },
              padding: {
                default: "0px 0px 0px 0px",
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
            },
          },
          fonts: {
            titulo: "'Cinzel', serif",
            descricao: "'Montserrat', sans-serif",
            family: {
              titulo: "'Cinzel', serif",
              descricao: "'Montserrat', sans-serif",
            },
            fontSize: {
              default: "12px",
              titulo: {
                default: "24px",
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "24px",
                xl: "28px",
              },
              descricao: {
                default: "12px",
                xs: "12px",
                sm: "12px",
                md: "12px",
                lg: "12px",
                xl: "14px",
              },
            },
            lineHeight: {
              titulo: {
                default: "1.5",
                xs: "1.5",
                sm: "1.5",
                md: "1.5",
                lg: "1.5",
                xl: "1.5",
              },
              descricao: {
                default: "1.5",
                xs: "1.5",
                sm: "1.5",
                md: "1.5",
                lg: "1.5",
                xl: "1.5",
              },
            },
            textAlign: {
              titulo: {
                default: "center",
                xs: "center",
                sm: "center",
                md: "center",
                lg: "center",
                xl: "center",
              },
              descricao: {
                default: "justify",
                xs: "justify",
                sm: "justify",
                md: "justify",
                lg: "justify",
                xl: "justify",
              },
            },
            fontWeight: {
              titulo: {
                default: "400",
                xs: "400",
                sm: "400",
                md: "400",
                lg: "400",
                xl: "400",
              },
              descricao: {
                default: "400",
                xs: "400",
                sm: "400",
                md: "400",
                lg: "400",
                xl: "400",
              },
            },
            margin: {
              titulo: {
                default: "0px 0px 0px 0px",
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
              descricao: {
                default: "15px 0px 0px 0px",
                xs: "15px 0px 0px 0px",
                sm: "15px 0px 0px 0px",
                md: "15px 0px 0px 0px",
                lg: "15px 0px 0px 0px",
                xl: "15px 0px 0px 0px",
              },
            },
            padding: {
              titulo: {
                default: "0px 0px 0px 0px",
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
              descricao: {
                default: "0px 20px 0px 20px",
                xs: "0px 20px 0px 20px",
                sm: "0px 20px 0px 20px",
                md: "0px 20px 0px 20px",
                lg: "0px 20px 0px 20px",
                xl: "0px 20px 0px 20px",
              },
            },
            fontColor: {
              titulo: {
                default: "#848484",
                gray: "#848484",
                white: "#fff",
                golden: "#d09e2c",
                lg: "#848484",
                xl: "#848484",
              },
              descricao: {
                default: "#848484",
                gray: "#848484",
                white: "#fff",
                md: "#848484",
                lg: "#848484",
                xl: "#848484",
              },
            },
          },
        }}
        padding={"0px"}
        fontWeight={"400"}
        importFont={
          "https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;700;800&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=EB+Garamond:wght@400;500;600;700;800&family=Noto+Sans+JP&family=Protest+Riot&family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=s"
        }
        fontFamily={"Cinzel"}
        btnSite="#000"
        colors={{ orange: "#ff8f00" }}
        btnPrimary={"#ff8f00"}
        DescricaoColor={"#ffffff"}
        TituloColor={"#ffffff"}
        bodyColor={"#fff"}
        CategoriasHomeColor={"#3ACBC7"}
        SeparadorColor={"#9ce5e3"}
        CorMenu={"#3ACBC7"}
        btnTextColor={"#FFFFFF"}
        accordionColor={"#9ce5e3"}
      />
      {/* <div className='config'>
        <IconButton aria-label="delete" size="large" onClick={MudaLayout}>
          {(layout == "none") ? <ToggleOffIcon fontSize="inherit" /> : <ToggleOnIcon fontSize="inherit" />}
        </IconButton>
      </div> */}
      <DefaultSeo
        title="DPNY Beach Hotel & SPA"
        description="Desfrute de uma combinação de instalações criativas e luxuosas, uma estrutura única de praia, SPA e Parque particular com vista mar, suítes com design artesanal luxuoso, gastronomia e serviços de excelência."
        dangerouslySetAllPagesToNoIndex={false}
        dangerouslySetAllPagesToNoFollow={false}
        openGraph={{
          type: "website",
          locale: "pt_BR",
          url: "https://dpny.com.br",
          site_name: "DPNY beach Hotel & SPA",
        }}
        twitter={{
          handle: "@seuTwitter",
          site: "@seuTwitter",
          cardType: "summary_large_image",
        }}
      />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="preload"
          href="/img/conceito-musical/page/conceito-musical-top.jpg"
          as="image"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;700;800&family=Cinzel+Decorative:wght@400;700;900&family=EB+Garamond:wght@400;500;600;700;800&family=Noto+Sans+JP&family=Protest+Riot&family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=s"
          rel="stylesheet"
        />
      </Head>
      <Component {...pageProps} />
    </SiteContextoProvider>
  );
};

App.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  const sessionId = await dataCookie({ ctx });
  const csrfToken = await getCsrfToken(sessionId);
  const menu = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}motor-menu/menu-site`,
    {
      headers: {
        Authorization: `Bearer ${csrfToken}`, // Exemplo de um token Bearer
        "Content-Type": "application/json",
        "x-csrf-token": csrfToken,
        "session-id": sessionId,
      },
    }
  );
  const responseRestricoes = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}motor-diarias/generico`,
    {
      headers: {
        Authorization: `Bearer ${csrfToken}`, // Exemplo de um token Bearer
        "Content-Type": "application/json",
        "x-csrf-token": csrfToken,
        "session-id": sessionId,
      },
    }
  );
  const responseConfiguracoes = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}dpny-configuracao`,
    {
      headers: {
        Authorization: `Bearer ${csrfToken}`, // Exemplo de um token Bearer
        "Content-Type": "application/json",
        "x-csrf-token": csrfToken,
        "session-id": sessionId,
      },
    }
  );
  const menuData = menu.data;
  const restricoes = responseRestricoes.data;
  const configuracoes = responseConfiguracoes.data;
  return { menuData, restricoes, configuracoes };
};

export default App;
